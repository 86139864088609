import { Box } from "@mui/material";
import Image from "mui-image";
import { ReactNode } from "react";
import { GoVideo } from "react-icons/go";
import { FaRegFileLines } from "react-icons/fa6";
import { FaRegImage } from "react-icons/fa";

type Props = {
    imageSrc?: string | null;
    children: ReactNode;
    thumbnail?: 'image' | 'video' | 'file';
    downloadIcon?: ReactNode;
}

const mediaType = {
    'image': <FaRegImage />,
    'video': <GoVideo />,
    'file': <FaRegFileLines />
}

const CustomCardSm = ({ imageSrc, children, thumbnail = 'image', downloadIcon }: Props) => {
    return (
        <div className="card-v2">
            <Box className="img-container">
                {imageSrc ? <Image
                    duration={600}
                    src={imageSrc}
                    showLoading
                    fit="cover"
                />
                    :
                    <Box sx={{ fontSize: "72px" }}>
                        {mediaType[thumbnail]}
                    </Box>}
            </Box>

            <Box className="card-body">
                {children}
            </Box>
            {downloadIcon ? downloadIcon : null}
        </div>
    );
}

export default CustomCardSm;