import React from 'react';
import { Box, Typography, Button } from "@mui/material";
import ModalWrapper from './ModalWrapper';
import { BiLogOut } from "react-icons/bi";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ButtonCustom from '../ButtonCustom';

type LogoutModalProps = {
    open: boolean;
    handleClose: () => void;
    handleLogout: () => void;
};

const LogoutModal: React.FC<LogoutModalProps> = ({ open, handleClose, handleLogout }) => {
    return (
        <ModalWrapper open={open} handleClose={handleClose}>
            <Box className="logout-modal-wrapper">
                <Box className="icon-container">
                    <BiLogOut className='logout-icon' />
                </Box>
                <Typography id="transition-modal-description" className='title'>
                    Are you sure you want to Logout?
                </Typography>
                <Box className="button-container">
                    <ButtonCustom onClick={handleLogout} endIcon={<ArrowForwardIcon sx={{ rotate: "-45deg" }} />}>
                        Confirm
                    </ButtonCustom>
                    <ButtonCustom onClick={handleClose} variant="secondary" endIcon={<ArrowForwardIcon sx={{ rotate: "-45deg" }} />}>
                        Cancel
                    </ButtonCustom>
                </Box>
            </Box>
        </ModalWrapper>
    );
};

export default LogoutModal;
