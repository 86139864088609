import { Outlet } from "react-router-dom";
import PrivateGuard from "../guards/PrivateGuard";
import PublicGuard from "../guards/PublicGuard";


export const PrivateGuardLayout = () => (
    <PrivateGuard>
        <Outlet />
    </PrivateGuard>
);

export const PublicGuardLayout = () => (
    <PublicGuard>
        <Outlet />
    </PublicGuard>
);
