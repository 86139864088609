import React from 'react';
import { Box, Card, Grid, Typography, IconButton, Button } from '@mui/material';
import { SxProps } from '@mui/system';
import Image from 'mui-image';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import EllipsisText from './EllipsisText ';
import { Link, createSearchParams, useNavigate } from 'react-router-dom';
import TextButton from './TextButton';
import CustomCardSm from './CustomCardSm';

interface EventCardV2Props {
  id: number | string;
  imageSrc: string;
  topBorderColor?: string;
  date: Date;
  title: string;
  description: string;
  sx?: SxProps;
  isUpcoming?: boolean;
}

const EventCardV2: React.FC<EventCardV2Props> = ({
  id,
  topBorderColor,
  imageSrc,
  title,
  date,
  description,
  sx,
  isUpcoming
}) => {
  const navigate = useNavigate();

  return (
    <CustomCardSm imageSrc={imageSrc}>
      <>
        <Box className="date" component="span">
          {date.toLocaleDateString('en-US', {
            month: 'long',
            day: 'numeric',
            year: 'numeric',
          })}
        </Box>

        <EllipsisText
          text={title}
          lineCount={2}
          className="heading"
          component="h6"
        />

        <EllipsisText text={description} lineCount={3} className="description" />

        <Link to={isUpcoming ? `/event?id=${id}` : `/user/event?id=${id}`}>
          <TextButton content='Read More' arrow />
        </Link>
      </>
    </CustomCardSm >
  );
};

export default EventCardV2;
