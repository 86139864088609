import { Box, Typography } from "@mui/material";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { SxProps, Theme } from '@mui/system';

interface ArrowIconProps {
    content: string;
    arrowLeft?: boolean;
    arrowRight?: boolean;
    sx?: SxProps<Theme>;
}

const ArrowIcon: React.FC<ArrowIconProps> = ({ content, arrowLeft, arrowRight, sx }) => {
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: "center",
                justifyContent: 'flex-start',
                gap: "6px",
                flexGrow: 1,
                '&:hover': {
                    backgroundColor: 'transparent',
                },
                ...sx,
            }}
        >
            {arrowLeft && (
                <ArrowForwardIcon
                    fontSize='small'
                    sx={{
                        transform: "rotate(180deg)",
                    }}
                />
            )}
            <Typography color="primary">{content}</Typography>
            {arrowRight && <ArrowForwardIcon fontSize='small' />}
        </Box>
    );
}

export default ArrowIcon;
