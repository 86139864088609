import { useEffect, useMemo, useState } from "react";
import CardMultiBordered from "../components/CardMultiBordered";
import PaginationCustom from "../components/PaginationCustom";
import WelcomeSection from "../sections/WelcomeSection";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import { getBookmarks } from "../api/api";
import { APP_CONSTANTS } from "../constants/app.constants";

const BookMarksPage = () => {
    const [page, setPage] = useState(1);
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const pageNo = searchParams.get('page') as string;
    const navigate = useNavigate();
    const [bookmarks, setBookmarks] = useState<any>([]);

    const getRegisteredEventsHandler = async () => {
        try {
            const userId = localStorage.getItem(APP_CONSTANTS.LOCAL_STORAGE_TOKEN_KEY) || "";
            const response = await getBookmarks(userId);
            setBookmarks(response?.data?.bookmarks);
        } catch (error) {
            console.error("Error fetching bookmarks:", error);
        }
    };

    useEffect(() => {
        getRegisteredEventsHandler();
    }, []);

    // set initial page from query params
    useEffect(() => {
        if (pageNo) {
            setPage(Number(pageNo));
        }
    }, [pageNo])

    const navigateTo = (pageIndex: number) => {
        let params = {
            page: `${pageIndex}`,
        };

        navigate({
            pathname: '/bookmarks',
            search: `?${createSearchParams(params)}`,
        });
    };

    // handle pagination clicks
    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        navigateTo(value);
        setPage(value);
    };
    const totalSlide = useMemo(() => bookmarks?.length, [bookmarks]);

    return (
        <>
            <WelcomeSection lastLogin={true} />

            <div className="custom-container pt-40 pb-80">
                <h3 className="main-heading32 mb-28">Bookmarks</h3>
                <div className="pb-12">
                    {
                        bookmarks?.length ?
                            <>
                                {
                                    bookmarks?.map((item: any) => (
                                        <CardMultiBordered key={item.id} item={item} />
                                    ))
                                }
                            </>
                            :
                            <div className="text-center mb-40 text-muted">No bookmarks found.</div>
                    }
                </div>
                <PaginationCustom count={totalSlide} page={page} handleChange={handleChange} />
            </div>
        </>
    );
}

export default BookMarksPage;