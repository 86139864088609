import { Box } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";

type Props = {
    lastLogin?: boolean;
    description?: boolean;
}

const WelcomeSection = ({ lastLogin, description = false }: Props) => {
    const user = useSelector(
        (state: RootState) => state.user
    );

    return (
        <Box className='custom-container welcome-container' pt='50px'>
            <h2 className='heading40 text-left'>
                <span>Welcome,</span> {user.name}
            </h2>

            <div className='location-container text-gray'>
                <span>
                    {lastLogin ? `${user?.last_login ? `Last Logged In: ${user.last_login}` : ""}` : "MDS, FACC"}
                </span>
                <span>
                    {user?.city}, {user?.state}
                </span>
            </div>

            {description &&
                <p className='text-gray font-manrope'>
                    Access our comprehensive collection of medical event resources. Stay informed and ahead of the curve with our downloadable materials.
                </p>
            }
        </Box>
    );
}

export default WelcomeSection;
