import { Box } from "@mui/material";
import EventSliderSectionV2 from "../sections/EventSliderSectionV2";
import dummyEvents from '../data/events.json';
import { RootState } from "../redux/store";
import { useSelector } from "react-redux";

interface UpcomingEventsSectionProps {
    title: string;
}

const UpcomingEventsSection: React.FC<UpcomingEventsSectionProps> = ({
    title
}) => {
    const { upcomingEventsData, upcomingEventsLoading } = useSelector(
        (state: RootState) => state.api
    );

    return (
        <Box
            className='custom-container'
            sx={{
                paddingBottom: "20px"
            }}
        >
            <EventSliderSectionV2
                title={title}
                themeColor={'#E14E61'}
                data={upcomingEventsData as any}
            />
        </Box>
    );
}

export default UpcomingEventsSection;
