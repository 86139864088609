import React, { ReactNode } from "react";
import Button from '@mui/material/Button';
import { CircularProgress } from "@mui/material";

type ButtonCustomProps = {
    children: ReactNode;
    variant?: "primary" | "secondary";
    startIcon?: ReactNode;
    endIcon?: ReactNode;
    onClick?: () => void;
    className?: string;
    type?: 'button' | 'submit';
    disabled?: boolean;
    loading?: boolean;
};

const ButtonCustom: React.FC<ButtonCustomProps> = ({ children, variant = "primary", startIcon, endIcon, onClick, className, type = 'button', disabled, loading }) => {
    return (
        <Button
            variant={variant === 'primary' ? "contained" : "outlined"}
            startIcon={startIcon}
            endIcon={(loading && disabled) ? <CircularProgress size={18} /> : endIcon}
            className={`custom-btn ${className}`}
            onClick={onClick}
            type={type}
            disabled={disabled}
        >
            {children}
        </Button>
    );
}

export default ButtonCustom;
