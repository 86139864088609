import { TextField } from "@mui/material";
import { useField } from "formik";

interface InputWithLabelProps {
  label: string;
  required?: boolean;
  placeholder?: string;
  type?: string;
  name?: string;
  onChange?: (e: React.ChangeEvent<any>) => void;
  onBlur?: (e: React.FocusEvent<any, Element>) => void;
  value?: string | number;
}

export function InputWithLabel({ label, required = false, ...props }: InputWithLabelProps) {
  const [field, meta] = useField(props.name || "");

  return (
    <div className="input-container">
      <label className="input-label">
        {label}{required ? "*" : ""}
      </label>
      <TextField
        {...field}
        {...props}
      />
      {meta.touched && meta.error && <span className="error">{meta.error}</span>}
    </div>
  );
}
