import { Box, Button, Grid, IconButton, Typography } from "@mui/material";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Link } from "react-router-dom";
import EventCardV2 from "../components/EventCardV2";
import EventSliderSectionV2 from "../sections/EventSliderSectionV2";
import dummyEvents from '../data/events.json';
import { useEffect, useState } from "react";
import { getRegisteredEvents } from "../api/api";
import { APP_CONSTANTS } from "../constants/app.constants";

interface RegisteredEventsSectionProps {
    title: string;
}

const RegisteredEventsSection: React.FC<RegisteredEventsSectionProps> = ({
    title
}) => {
    const [registeredEvents, setRegisteredEvents] = useState([]);

    const getRegisteredEventsHandler = async () => {
        try {
            const userId = localStorage.getItem(APP_CONSTANTS.LOCAL_STORAGE_TOKEN_KEY) || "";
            const response = await getRegisteredEvents(userId);
            setRegisteredEvents(response?.data?.events);
        } catch (error) {
            console.error("Error fetching registered events:", error);
        }
    };

    useEffect(() => {
        getRegisteredEventsHandler();
    }, [])

    return (
        <Box className='custom-container'>
            <EventSliderSectionV2
                title={title}
                themeColor={'#E14E61'}
                data={registeredEvents}
            />
        </Box>
    );
}

export default RegisteredEventsSection;