import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchEventFullDetailsData } from '../redux/apiSlice';
import { RootState } from '../redux/store';
import InvalidRoute from '../components/InvalidRoute';
import {
  ThemeProvider,
  createTheme,
} from '@mui/material';
import LinearIndeterminate from '../components/LinearIndeterminate';
import theme from '../theme/basic';
import BannerHeroSection from '../sections/BannerHeroSection';
import EventDetailsSection from '../sections/EventDetailsSection';
import CoursePresentationSection from '../sections/CoursePresentationSection';
import { eventFullDetailsData, getEventPresentations, getRegisteredEvents } from '../api/api';
import { APP_CONSTANTS } from '../constants/app.constants';

const EventPageAuthenticated: React.FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const eventID = searchParams.get('id') as string;
  const [showMessageTab, setShowMessageTab] = useState<boolean>(false);
  const heroImage = "/images/pexels-andrea-piacquadio-845451 1.png";

  const [registeredEvent, setRegisteredEvent] = useState<any>({});

  const getRegisteredEventsHandler = async () => {
    try {
      const userId = localStorage.getItem(APP_CONSTANTS.LOCAL_STORAGE_TOKEN_KEY) || "";
      const response = await getRegisteredEvents(userId);

      const event = response?.data?.events?.filter((item: any) => item.id == eventID)[0];

      setRegisteredEvent((old: any) => ({ ...old, details: event }));
    } catch (error) {
      console.error("Error fetching registered events:", error);
    }
  };

  const getPresentationsHandler = async () => {
    try {
      const eprresponse = await getEventPresentations(eventID);
      setRegisteredEvent((old: any) => ({ ...old, presentations: eprresponse?.data?.presentations }));
    } catch (error) {
      console.error("Error fetching event presentations:", error);
    }
  };

  const getLocationsHandler = async () => {
    try {
      const eventFullDetails = await eventFullDetailsData(eventID);
      setRegisteredEvent((old: any) => ({ ...old, locations: eventFullDetails?.data?.locations }));
    } catch (error) {
      console.error("Error fetching event locations:", error);
    }
  };



  useEffect(() => {
    getRegisteredEventsHandler();
    getPresentationsHandler();
    getLocationsHandler();
  }, [eventID]);


  return (
    <>
      <BannerHeroSection image={registeredEvent?.details?.featured_image} />
      {!!registeredEvent?.details &&
        <EventDetailsSection data={registeredEvent?.details} locations={registeredEvent?.locations} />
      }

      <CoursePresentationSection data={registeredEvent?.presentations} />
    </>
  );
};

export default EventPageAuthenticated;
