import { Pagination, PaginationItem } from "@mui/material";
import ArrowIcon from "./ArrowIcon";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";

type Props = {
    page: number;
    count: number;
    handleChange: (event: React.ChangeEvent<unknown>, value: number) => void;
}

const PaginationCustom = ({ page, count, handleChange }: Props) => {
    const { size } = useSelector((state: RootState) => state.mquery);

    const ArrowComponent: React.FC<{ direction: 'left' | 'right' }> = ({ direction }) => (
        <ArrowIcon
            arrowLeft={direction === 'left'}
            arrowRight={direction === 'right'}
            content={direction === 'left' ? "Previous" : "Next"}
        />
    );

    return (
        <div className="custom-pagination">
            <Pagination
                count={count}
                page={page}
                siblingCount={size === 'xs' ? 0 : undefined}
                onChange={handleChange}
                renderItem={(item) => (
                    <PaginationItem
                        slots={{
                            previous: () => <ArrowComponent direction="left" />,
                            next: () => <ArrowComponent direction="right" />,
                        }}
                        {...item}
                    />
                )}
            />
        </div>
    );
}

export default PaginationCustom;