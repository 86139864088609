import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { APP_CONSTANTS } from '../constants/app.constants';
import { ROUTES_CONSTANTS } from '../constants/routes.constants';

type GuardPropTypes = {
  children: ReactNode;
};
const PublicGuard = ({ children }: GuardPropTypes) => {
  if (localStorage.getItem(APP_CONSTANTS.LOCAL_STORAGE_TOKEN_KEY)) {
    return <Navigate to={ROUTES_CONSTANTS.EVENTS.AUTH_EVENTS} />;
  }

  return <>{children}</>;
};

export default PublicGuard;
