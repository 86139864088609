import { Box } from "@mui/material";

const BannerHeroSection = ({ image }: { image: string }) => {
    return (
        <Box
            sx={{
                position: 'relative',
                width: '100%',
                height: '480px',
                backgroundImage: `url('${image}')`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: "no-repeat",
                '&::before': {
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 65, 141, 0.75)',
                }
            }}
        />
    );
}

export default BannerHeroSection;