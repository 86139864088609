import * as Yup from "yup";

export const loginValidationSchema = Yup.object({
  email: Yup.string()
    .email("Invalid email address format")
    .required("This field is required"),
  phone: Yup.string().required("This field is required"),
});

export const otpValidationSchema = Yup.object({
  otp: Yup.string().required("This field is required"),
});
