import { Box } from "@mui/material";
import ButtonCustom from "../../components/ButtonCustom";
import { InputWithLabel } from "../../components/InputWithLabel";
import AuthLayout from "../../layouts/AuthLayout";
import { Form, Formik } from "formik";
import { loginInitialValues, otpInitialValues } from "./validations/initialValues";
import { loginValidationSchema, otpValidationSchema } from "./validations";
import { useEffect, useState } from "react";
import { convertSecondsToMinutes } from "../../util/convertSecondsToMinutes";
import { useNavigate } from "react-router-dom";
import { getUserProfile, postLogin, postOtp } from "../../api/api";
import { toast } from "react-toastify";
import { ROUTES_CONSTANTS } from "../../constants/routes.constants";
import { APP_CONSTANTS } from "../../constants/app.constants";
import { UserState, setUser } from "../../redux/userSlice";
import { useDispatch } from "react-redux";

const otpTimer = 300;

const LoginPage = () => {
    const [loading, setLoading] = useState(false);
    const [showOtp, setShowOtp] = useState(false);
    const [counter, setCounter] = useState(0);
    const [isResendDisabled, setIsResendDisabled] = useState(false);
    const [emailAndPhone, setEmailAndPhone] = useState({ email: "", phone: "" });
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        if (showOtp) {
            const timer: any = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
            // Disable the resend button when the counter is active
            setIsResendDisabled(counter > 0);

            // Reset the counter and enable the resend button when it reaches 0
            if (counter === 0) {
                clearInterval(timer);
                setIsResendDisabled(false);
            }

            return () => clearInterval(timer);
        }
    }, [counter, showOtp]);

    const sendOtpHandler = async (values: any) => {
        setShowOtp(false);
        setCounter(otpTimer);

        // setLoading(true);
        if (values.email && values.phone) {
            setEmailAndPhone({ email: values.email, phone: values.phone })
            const formData = new FormData();
            formData.append('mobile', values.phone);
            formData.append('email', values.email);

            const response = await postLogin(formData);
            if (response) {
                setShowOtp(true);
                setLoading(false);
            }

        }
    }

    const submitOtpFormHandler = async (values: any) => {
        localStorage.removeItem(APP_CONSTANTS.LOCAL_STORAGE_TOKEN_KEY);
        setLoading(true);

        const formData = new FormData();
        formData.append('email', emailAndPhone.email);
        formData.append('mobile', emailAndPhone.phone);
        formData.append('otp', values.otp);

        try {
            const response = await postOtp(formData);

            if (response?.data) {
                localStorage.setItem(APP_CONSTANTS.LOCAL_STORAGE_TOKEN_KEY, response?.data?.user_id);
                const userId = response?.data?.user_id;
                // make api call with userId
                const profileResponse = await getUserProfile(userId);
                const userInfo: UserState = profileResponse?.data?.profile;
                dispatch(setUser(userInfo));
                localStorage.setItem('userInfo', JSON.stringify(userInfo))
                // success and redirect
                toast.success(response?.data?.message);
                navigate(ROUTES_CONSTANTS.EVENTS.AUTH_EVENTS);
            };
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    }

    return (
        <AuthLayout>
            <div className="auth-card-custom">
                <div>
                    <h2 className="auth-heading40">Login</h2>
                    <p className="auth-subtitle">
                        Unlock access to events and content
                        with your login credentials.
                    </p>

                    <Formik initialValues={loginInitialValues} validationSchema={loginValidationSchema} onSubmit={sendOtpHandler}>
                        {
                            ({ values, handleChange, handleBlur }) => (
                                <Form>
                                    <div className="form-container">
                                        <InputWithLabel
                                            label="Registered Email"
                                            required
                                            placeholder="Enter Email Address"
                                            name="email"
                                            onChange={handleChange}
                                            value={values.email}
                                            onBlur={handleBlur}
                                        />
                                        <InputWithLabel
                                            label="Registered mobile No."
                                            required
                                            placeholder="Enter Mobile Number"
                                            name="phone"
                                            onChange={handleChange}
                                            value={values.phone}
                                            onBlur={handleBlur}
                                        />
                                        <ButtonCustom variant="secondary" type='submit' disabled={loading || isResendDisabled}> Generate OTP </ButtonCustom>
                                    </div>
                                </Form>
                            )
                        }
                    </Formik>

                    {showOtp &&
                        <>
                            <p className="error">OTP sent to your registered credentials</p>
                            <p className="error text-right">Time left: {convertSecondsToMinutes(counter)}</p>

                            <Formik initialValues={otpInitialValues} validationSchema={otpValidationSchema} onSubmit={submitOtpFormHandler}>
                                {
                                    ({ values, handleChange, handleBlur }) => (
                                        <Form>
                                            <div className="form-container mt-12">
                                                <InputWithLabel
                                                    label=""
                                                    placeholder="Enter OTP"
                                                    name="otp"
                                                    onChange={handleChange}
                                                    value={values.otp}
                                                    onBlur={handleBlur}
                                                />
                                                <Box sx={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    gap: "16px"
                                                }}>
                                                    <ButtonCustom type='submit' disabled={loading}> Submit </ButtonCustom>
                                                </Box>
                                            </div>
                                        </Form>
                                    )
                                }
                            </Formik>
                        </>
                    }

                </div>
            </div>
        </AuthLayout>
    );
}

export default LoginPage;
