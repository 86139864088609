import { Box, Typography } from "@mui/material";
import { FaRegEnvelope } from "react-icons/fa";

const EventDetailsSection = ({ data, locations }: { data: any, locations?: any }) => {
    return (
        <Box className="custom-container">
            <Box className="event-details-container-auth">
                <h1 className="heading-primary">
                    {data.title}
                </h1>

                <Box
                    className="date-location"
                    sx={{
                        // display: "flex",
                        // alignItems: "center",
                        // gap: "80px"
                    }}
                >
                    <Box sx={{
                        display: "flex",
                        alignItems: "start",
                        gap: "16px"
                    }}>
                        <Box className="icon">
                            <FaRegEnvelope />
                        </Box>
                        <Box>
                            {data.event_date}
                        </Box>
                    </Box>

                    <Box sx={{
                        display: "flex",
                        alignItems: "start",
                        gap: "16px"
                    }}>
                        <Box className="icon">
                            <svg width="15" height="20" viewBox="0 0 15 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7.5 14.475C9.15 13.1417 10.3958 11.8583 11.2375 10.625C12.0792 9.39167 12.5 8.23333 12.5 7.15C12.5 6.21667 12.3292 5.42083 11.9875 4.7625C11.6458 4.10417 11.225 3.57083 10.725 3.1625C10.225 2.75417 9.68333 2.45833 9.1 2.275C8.51667 2.09167 7.98333 2 7.5 2C7.01667 2 6.48333 2.09167 5.9 2.275C5.31667 2.45833 4.775 2.75417 4.275 3.1625C3.775 3.57083 3.35417 4.10417 3.0125 4.7625C2.67083 5.42083 2.5 6.21667 2.5 7.15C2.5 8.23333 2.92083 9.39167 3.7625 10.625C4.60417 11.8583 5.85 13.1417 7.5 14.475ZM7.5 17C5.15 15.2667 3.39583 13.5833 2.2375 11.95C1.07917 10.3167 0.5 8.71667 0.5 7.15C0.5 5.96667 0.7125 4.92917 1.1375 4.0375C1.5625 3.14583 2.10833 2.4 2.775 1.8C3.44167 1.2 4.19167 0.75 5.025 0.45C5.85833 0.15 6.68333 0 7.5 0C8.31667 0 9.14167 0.15 9.975 0.45C10.8083 0.75 11.5583 1.2 12.225 1.8C12.8917 2.4 13.4375 3.14583 13.8625 4.0375C14.2875 4.92917 14.5 5.96667 14.5 7.15C14.5 8.71667 13.9208 10.3167 12.7625 11.95C11.6042 13.5833 9.85 15.2667 7.5 17ZM7.5 9C8.05 9 8.52083 8.80417 8.9125 8.4125C9.30417 8.02083 9.5 7.55 9.5 7C9.5 6.45 9.30417 5.97917 8.9125 5.5875C8.52083 5.19583 8.05 5 7.5 5C6.95 5 6.47917 5.19583 6.0875 5.5875C5.69583 5.97917 5.5 6.45 5.5 7C5.5 7.55 5.69583 8.02083 6.0875 8.4125C6.47917 8.80417 6.95 9 7.5 9ZM0.5 20V18H14.5V20H0.5Z" fill="currentColor" />
                            </svg>
                        </Box>
                        <Box>
                            {
                                locations?.map((item: any) => <span key={item?.location_name}>{item?.location_name}, </span>)
                            }
                        </Box>
                    </Box>
                </Box>

                <Typography component='div'>
                    <div dangerouslySetInnerHTML={{
                        __html: data.content
                    }} />
                </Typography>
            </Box>
        </Box>
    );
}

export default EventDetailsSection;
