import { Box, Grid } from "@mui/material";
import { ReactNode } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import LogoImage from "../components/LogoImage";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const AuthLayout = ({ children }: { children: ReactNode }) => {
    const { siteBasicInfoData } = useSelector(
        (state: RootState) => state.api
    );
    const coverImageRight = "images/pexels-jibarofoto-2774566 1.png"

    const logoImage = siteBasicInfoData?.data?.logo;
    const logoUrl = siteBasicInfoData?.data?.url;

    return (
        <Grid container>
            <Grid
                item
                xs={12}
                md={6}
            >
                <Box className='topbar-container'>
                    <Box className='custom-logo-wrapper'>
                        <Box>
                            <LogoImage
                                imgSrc={logoImage}
                                url={logoUrl}
                                height={23}
                                width={117}
                            />
                        </Box>
                    </Box>
                </Box>
                <Box className='auth-layout-main-container'>
                    {children}
                </Box>
            </Grid>
            <Grid
                item
                xs={12}
                md={6}
                className="auth-layout-bg-image-container"
            >
                <div
                    style={{
                        minHeight: '100vh',
                        height: "100%",
                        background: `linear-gradient(
                                rgba(0, 65, 141, 0.75),
                                rgba(0, 65, 141, 0.75)), 
                                url('${coverImageRight}')`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                    }}
                />
            </Grid>

            <ToastContainer
                position="bottom-right"
                autoClose={3000}
            />
        </Grid>
    );
}

export default AuthLayout;