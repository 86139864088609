import { Navigate } from 'react-router-dom';
import { APP_CONSTANTS } from '../constants/app.constants';
import { ROUTES_CONSTANTS } from '../constants/routes.constants';
import React, { ReactNode } from 'react';

type GuardPropTypes = {
  children: ReactNode;
};
const PrivateGuard = ({ children }: GuardPropTypes) => {
  if (!localStorage.getItem(APP_CONSTANTS.LOCAL_STORAGE_TOKEN_KEY)) {
    return <Navigate to={ROUTES_CONSTANTS.AUTH_ROUTES.LOGIN} />;
  }

  return <>{children}</>;
};

export default PrivateGuard;
