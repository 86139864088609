import React, { ReactNode } from 'react';
import { Backdrop, Box, Fade, Modal } from "@mui/material";

type ModalWrapperProps = {
    open: boolean;
    handleClose: () => void;
    children: ReactNode;
};

const ModalWrapper: React.FC<ModalWrapperProps> = ({ open, handleClose, children }) => {
    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleClose}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
                backdrop: {
                    timeout: 500,
                },
            }}
        >
            <Fade in={open}>
                <Box>
                    {children}
                </Box>
            </Fade>
        </Modal>
    );
};

export default ModalWrapper;
