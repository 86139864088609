export const ROUTES_CONSTANTS = {
  AUTH_ROUTES: {
    INDEX: "/",
    LOGIN: "/login",
    getLoginPath: function () {
      return this.INDEX + this.LOGIN;
    },
  },
  WILDCARD: "*",
  EVENTS: {
    INDEX: "/events",
    AUTH_EVENTS: "/user/events",
    AUTH_EVENT: "/user/event",
  },
  BOOKMARKS: {
    INDEX: "/bookmarks",
  },
};
