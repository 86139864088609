import React from 'react';
import { Button, IconButton, Typography } from "@mui/material";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

interface Props {
    content: string;
    arrow?: boolean;
    arrowLeft?: boolean;
    onClick?: () => void;
    className?: string;
    disabled?: boolean;
}

const TextButton: React.FC<Props> = ({ content, arrow, arrowLeft, onClick, className, disabled }) => {
    return (
        <Button
            component={IconButton}
            color="primary"
            onClick={onClick}
            className={className}
            disabled={disabled}
            sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                gap: "6px",
                flexGrow: 1,
                '&:hover': {
                    backgroundColor: 'transparent',
                },
            }}
        >
            {arrowLeft && (
                <ArrowForwardIcon
                    fontSize='small'
                    sx={{
                        transform: "rotate(180deg)",
                    }}
                />
            )}
            <Typography color="primary">{content}</Typography>
            {arrow && <ArrowForwardIcon fontSize='small' />}
        </Button>
    );
};

export default TextButton;
