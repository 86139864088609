import { Outlet } from 'react-router';
import NavBar from '../sections/HeaderSection';
import FooterSection from '../sections/FooterSection';
import { ToastContainer } from 'react-toastify';

const WithNavFooter = () => {
    return (
        <>
            <NavBar />
            <div className="pt-68">
                <Outlet />
                <ToastContainer
                    position="bottom-right"
                    autoClose={3000}
                />
            </div>
            <FooterSection />
        </>
    );
};

export default WithNavFooter;