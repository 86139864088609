import { Box, Button, Grid } from "@mui/material";
import CustomCardSm from "./CustomCardSm";
import { GrDownload } from "react-icons/gr";
import { MdOutlineBookmarkBorder } from "react-icons/md";
import { createBookmark } from "../api/api";
import { toast } from "react-toastify";
import { APP_CONSTANTS } from "../constants/app.constants";

const CardMultiBordered = ({ item }: { item: any }) => {

    const handleBookmark = async (bookId: string) => {
        const userId = localStorage.getItem(APP_CONSTANTS.LOCAL_STORAGE_TOKEN_KEY) || "";

        const formData = new FormData();
        formData.append('user_id', userId);
        formData.append('bookmark_id', bookId);
        formData.append('action', 'add');

        const res = await createBookmark(formData);

        if (res?.data?.is_success) {
            toast.success(res?.data?.message);
        }
    };

    return (
        <Box className="custom-card-multi-border">
            <Box className="top">
                <Box sx={{
                    display: "flex",
                    gap: "20px"
                }}>
                    <Box>
                        <h5 className="title" dangerouslySetInnerHTML={{ __html: item.title }} />
                        <Box className="subtitle"
                            dangerouslySetInnerHTML={{ __html: item.subtitle }}
                        />
                        <Box
                            className="prof"
                            dangerouslySetInnerHTML={{ __html: item.presentor_name }}
                        />
                    </Box>
                    <Button
                        className="rounded-btn"
                        sx={{ mr: "34px", marginTop: "0 !important" }}
                        onClick={() => handleBookmark(item.id)}
                    >
                        <MdOutlineBookmarkBorder size={24} />
                    </Button>
                </Box>

            </Box>

            <Box className="bottom">

                <Grid container>
                    {!!item.video1 &&
                        <Grid
                            item
                            xs={12}
                            sm={6}
                            md={4}
                            key={item.video1}
                        >
                            <Box m={"10px"} pb="12px" height="100%">
                                <CustomCardSm
                                    thumbnail='video'
                                    downloadIcon={
                                        <a href="#" download={item.video1} target="_blank">
                                            <Button className="rounded-btn download">
                                                <GrDownload />
                                            </Button>
                                        </a>
                                    }
                                >
                                    <Box>
                                        <Box className="fz-17 fw-700">
                                            {item?.video1?.split('/')?.pop()}
                                        </Box>
                                    </Box>
                                </CustomCardSm>
                            </Box>
                        </Grid>
                    }
                    {!!item.video2 &&
                        <Grid
                            item
                            xs={12}
                            sm={6}
                            md={4}
                            key={item.video2}
                        >
                            <Box m={"10px"} pb="12px" height="100%">
                                <CustomCardSm
                                    thumbnail='video'
                                    downloadIcon={
                                        <a href="#" download={item.video2} target="_blank">
                                            <Button className="rounded-btn download">
                                                <GrDownload />
                                            </Button>
                                        </a>
                                    }
                                >
                                    <Box>
                                        <Box className="fz-17 fw-700">
                                            {item?.video2?.split('/')?.pop()}
                                        </Box>
                                    </Box>
                                </CustomCardSm>
                            </Box>
                        </Grid>
                    }
                    {!!item.pdf &&
                        <Grid
                            item
                            xs={12}
                            sm={6}
                            md={4}
                            key={item.pdf}
                        >
                            <Box m={"10px"} pb="12px" height="100%">
                                <CustomCardSm
                                    thumbnail='file'
                                    downloadIcon={
                                        <a href="#" download={item.pdf} target="_blank">
                                            <Button className="rounded-btn download">
                                                <GrDownload />
                                            </Button>
                                        </a>
                                    }
                                >
                                    <Box>
                                        <Box className="fz-17 fw-700">
                                            {item.pdf?.split('/')?.pop()}
                                        </Box>
                                    </Box>
                                </CustomCardSm>
                            </Box>
                        </Grid>
                    }
                </Grid>
            </Box>
        </Box>
    );
}

export default CardMultiBordered;

const dummyData = [
    {
        title: "Trailer.mp4"
    },
    {
        title: "Full video presentation.mp4"
    },
    {
        title: "Presentation slides.pdf"
    }
]