import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface UserState {
  name: string;
  email: string;
  mobile: string;
  address: string;
  city: string;
  state: string;
  pincode: string;
  qualification: string;
  registered_event_id: string;
  last_login: string;
}

const {
  name,
  email,
  mobile,
  address,
  city,
  state,
  pincode,
  qualification,
  registered_event_id,
  last_login,
} = JSON.parse(localStorage.getItem("userInfo") || "{}");

const initialState: UserState = {
  name,
  email,
  mobile,
  address,
  city,
  state,
  pincode,
  qualification,
  registered_event_id,
  last_login,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<Partial<UserState>>) => {
      Object.assign(state, action.payload);
    },
  },
});

export const { setUser } = userSlice.actions;

export default userSlice.reducer;
