interface AvatarProps {
    name: string;
}

const Avatar: React.FC<AvatarProps> = ({ name = '' }) => {
    const initials = name ? name.charAt(0).toUpperCase() : '';

    return (
        <div className="user-avatar">
            {initials}
        </div>
    );
}

export default Avatar;
