import { Box } from "@mui/material";
import CardMultiBordered from "../components/CardMultiBordered";

const CoursePresentationSection = ({ data }: { data: any }) => {
    return (
        <Box className={`custom-container ${data?.length ? "" : "pt-40"}`}>
            <h3 className="main-heading32 mb-28">Course Presentations</h3>
            {
                data?.length ?
                    <>
                        {
                            data?.map((item: any) => (
                                <CardMultiBordered key={item.id} item={item} />
                            ))
                        }
                    </>
                    :
                    <div className="text-center mb-40 text-muted">No presentations found.</div>
            }

        </Box>
    );
}

export default CoursePresentationSection;